import { Injectable } from '@angular/core';

import { fetchUserAttributes, FetchUserAttributesOutput } from 'aws-amplify/auth';
import { LoggingService } from '@inspiring-health/mea-commons';
import { from, Observable, of } from 'rxjs';
import { Project } from '../models/project.model';
import { AppConstants } from '../shared/app.constants';
import { S3Service } from './s3.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  externalProjectName: string = ""
  groupForExternalProject = ""
  calledFromOtherApp: boolean = false

  constructor(private loggingService: LoggingService, private s3Service: S3Service) {

  }

  retrieveProjects(): Observable<any> {
    this.log("retrieveProjects");
    let projectArray: Project[] = [];
    //TODO: get real creation date of user group -> write AWSService to encapsulate needed operations
    let now: Date = new Date();
    let customerName: string;
    let projectName: string;
    if (!this.calledFromOtherApp) {
      this.log("retrieveProjects => not called from other app")
      return from(this.getCurrentUser().then(user => {
        this.log("retrieveProjects => user is: " + user.name);
        return this.s3Service.getUserGroups().then(projects => {
          projects.forEach(fullProjectName => {
            this.log("retrieveProjects => Project name: " + fullProjectName);
            if (fullProjectName.startsWith(AppConstants.PROJECTNAMEPREFIX)) {
              this.log("retrieveProjects => Project name with prefix: " + fullProjectName);
              projectName = fullProjectName.substring(AppConstants.PROJECTNAMEPREFIXLENGTH);
              let lastCustomerAttributeNumber: number = -1;
              let customerAttribute = this.getNextCustomerAttribute(user, lastCustomerAttributeNumber);
              if (projectName.startsWith(AppConstants.PROJECTWITHFOLDERMARKER)) {
                const project: Project = this.makeFolderProject(projectName, fullProjectName, now);
                projectArray.push(project);
              }
              else {
                while (customerAttribute !== undefined) {
                  this.log("retrieveProjects => User attribute custom:customerN value: " + customerAttribute);
                  this.s3Service.getObjects(fullProjectName, customerAttribute).then(objectsCommandOutput => {
                    const objectPrefix: string = objectsCommandOutput.Prefix;
                    if (objectPrefix === undefined) {
                      customerName = 'unknown';
                    } else {
                      customerName = objectPrefix;
                    }
                    projectName = fullProjectName.substring(AppConstants.PROJECTNAMEPREFIXLENGTH);
                    this.log("retrieveProjects => Customer name: " + customerName);
                    this.log("retrieveProjects => Project name: " + projectName);
                    const project: Project = {
                      subProject: customerName,
                      name: projectName,
                      displayName: projectName,
                      relatedGroup: fullProjectName,
                      hasFolders: false,
                      creationDate: now
                    }
                    projectArray.push(project);
                  }).catch(e => this.logError("retrieveProjects => got no objects. Error: " + e));
                  lastCustomerAttributeNumber++;
                  customerAttribute = this.getNextCustomerAttribute(user, lastCustomerAttributeNumber);
                }
              }
            }
          })
          return projectArray;
        }).catch((e => this.logError("retrieveProjects => got no projects. Error: " + e)));
      }));
    } else {
      this.log("retrieveProjects => called from other app")
      const project: Project = {
        subProject: "",
        name: this.externalProjectName,
        displayName: this.externalProjectName,
        relatedGroup: this.groupForExternalProject,
        hasFolders: true,
        creationDate: now
      }
      projectArray.push(project)
      return of(projectArray)
    }
    //return of(PROJECTS);
  }

  private makeFolderProject(projectName: string, fullProjectName: string, now: Date) {
    this.log("makeFolderProject => Project without customer name but with folders");
    let folderProjectName: string = projectName.substring(AppConstants.PROJECTWITHFOLDERMARKER.length);
    let folderProjectDisplayName: string;
    if (this.isLinkedWithEpic(fullProjectName)) {
      folderProjectDisplayName = projectName.substring(0, projectName.indexOf("-epic-")).substring(AppConstants.PROJECTWITHFOLDERMARKER.length).concat(" (Epic)");
      this.log("makeFolderProject => Project name epic linked: " + folderProjectName);
    } else {
      folderProjectDisplayName = folderProjectName;
      this.log("makeFolderProject => Project name: " + folderProjectName);
    }
    const project: Project = {
      subProject: "",
      name: folderProjectName,
      displayName: folderProjectDisplayName,
      relatedGroup: fullProjectName,
      hasFolders: true,
      creationDate: now
    };
    return project;
  }

  private async getCurrentUser(): Promise<FetchUserAttributesOutput> {
    const user = await fetchUserAttributes();
    return user;
  }

  private getUserGroups(): Observable<string[]> {
    return from(this.s3Service.getUserGroups());
  }

  private getObjects(fullProjectName: string, customerAttribute: string): Observable<any> {
    return from(this.s3Service.getObjects(fullProjectName, customerAttribute));
  }

  private getCustomerAttributes(user: any): Observable<string[]> {
    let num: number = 0;
    let customerAttributes: string[] = [];
    let customerAttribute: string = user.attributes["custom:customer" + num];
    this.log("getCustomerAttributes customerAttribut: " + customerAttribute);
    customerAttributes[num] = customerAttribute;
    num++;
    while (customerAttribute) {
      customerAttribute = user.attributes["custom:customer" + num];
      this.log("getCustomerAttributes customerAttribut: " + customerAttribute);
      customerAttributes[num] = customerAttribute;
      num++;
    }
    return of(customerAttributes);
  }

  private getNextCustomerAttribute(user: FetchUserAttributesOutput, lastCustomerAttributeNumber: number): string | undefined {
    let nextCustomerAttrNum: number = lastCustomerAttributeNumber + 1;
    let attrName: string = "custom:customer" + nextCustomerAttrNum;
    this.log("getNextCustomerAttribute => for attribute " + attrName);
    return user[attrName];
  }

  private isLinkedWithEpic(groupName: string): boolean {
    const groupNameRegexp: RegExp = new RegExp(/^mea-data-exchange-(dev|qa|prod)-folder-\w+-epic-\d+$/)
    return groupNameRegexp.test(groupName)
  }

  private log(message: string) {
    this.loggingService.log(`ProjectService: ${message}`);
  }

  private logError(errorMessage: string) {
    this.loggingService.logError(`ProjectService: ${errorMessage}`);
  }

}