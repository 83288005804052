<div class="modal-header modal-header-ih">
  <h4 class="modal-title modal-title-ih">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('CROSS_CLICK')"></button>
</div>
<div class="modal-body modal-body-ih">
  <div *ngIf="folder">
    <div class="formwidget-ih">
      <label id="label-input-folder-name" for="foldername">{{label}}</label>
      <input id="foldername" class="form-control" required #name="ngModel" type="text" [(ngModel)]="folder.displayName"
      ngbAutoFocus #folderNameInput (focus)="folderNameInput.select()">
    </div>
  </div>
  <div class="modal-footer modal-footer-ih">
    <button type="button" class="btn btn-outline-dark submit-ih"
      (click)="activeModal.dismiss('CANCEL_CLICK')">{{cancelButtonText}}</button>
    <button type="button" class="btn btn-outline-dark submit-ih"
      (click)="activeModal.close('OK_CLICK')">{{okButtonText}}</button>
  </div>
</div>