import { Injectable } from '@angular/core';

import { DeleteObjectCommand, GetObjectCommand, ListObjectsCommand, ListObjectVersionsCommand, ObjectVersion, PutObjectCommand, S3Client, _Object, CreateBucketCommandInput, CreateBucketCommand, CopyObjectCommand } from '@aws-sdk/client-s3';

import { CognitoService, LoggingService } from '@inspiring-health/mea-commons';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { fetchUserAttributes, fetchAuthSession, AuthSession } from '@aws-amplify/auth';
import { AppConstants } from '../shared/app.constants';

const DELIMITER = '/';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  s3Client?: S3Client;


  constructor(private loggingService: LoggingService, private cognitoService: CognitoService) {

  }

  async createBucket(bucketName: string): Promise<any> {
    this.log("createBucket => for bucket: " + bucketName);
    return fetchUserAttributes().then(user => {
      this.log('createBucket => Signed in user: ' + user.username);
      return this.getCurrentSession().then(session => {
        this.initS3Client(session, bucketName);
        const input: CreateBucketCommandInput = {
          Bucket: bucketName,
          CreateBucketConfiguration: {
            LocationConstraint: "eu-central-1"
          }
        }
        const createBucketCommand: CreateBucketCommand = new CreateBucketCommand(input);
        return this.s3Client?.send(createBucketCommand).then(output => {
          this.log('createBucket => : createBucketCommandOutput httpStatusCode: ' + output.$metadata.httpStatusCode)
          return bucketName;
        }).catch(e => this.logError("createBucket => send failed. Exception is: " + e))
      }).catch(e => this.logError("createBucket => failed. No session. Exception is: " + e))
    }).catch(e => this.logError("createBucket => failed. No user. Exception is: " + e))
  }

  // TODO getUserGroups auslagern nach mea-commons und die dortige Methode public machen?
  async getUserGroups(): Promise<string[]> {
    this.log("getUserGroups => from user");
    return this.getCurrentSession().then(session => {
      this.log("getUserGroups => user session available");
      if (this.cognitoService.getJWT(session).payload != undefined) {
        const payload = this.cognitoService.getJWT(session).payload;
        if ('cognito:groups' in payload) {
          const groups = payload['cognito:groups']
          return groups as string[]
        }
      }
      return []
      //const jwt: string = idToken.getJwtToken();
      //let jwtData = jwt.split('.')[1];
      //let decodedJwtJsonData = window.atob(jwtData);
      //this.log("getUserGroups => decodedJwtJsonData: " + decodedJwtJsonData);
    },
      error => {
        this.logError("getUserGroups => " + error);
        return []
      });
  }

  async getObjects(bucketName: string, prefix: string): Promise<any> {
    this.log("getObjects => for bucket: " + bucketName + " and prefix " + prefix);
    return fetchUserAttributes().then(user => {
      this.log('getObjects => Signed in user: ' + user.username);
      //this.log("cognitoId: " + COGNITOID);
      return this.getCurrentSession().then(session => {
        this.initS3Client(session, bucketName);
        return this.s3Client?.send(new ListObjectsCommand({
          Bucket: bucketName,
          Prefix: prefix
        })).then(data => {
          const objects = data.Contents;
          objects?.forEach(element => {
            this.log("getObjects => Data from ListObjectsCommand: " + element.Key);
          })
          return data;
        }).catch(e => this.logError("getObjects => No objects " + e));
      }).catch(e => this.logError("getObjects => No session " + e));
    });
  }

  async copyObject(bucketName: string, source: string, destPath: string, destName: string): Promise<any> {
    this.log("copyObject => with name " + source + " in bucket: " + bucketName + " to directory " + destPath + " with destName " + destName);
    return fetchUserAttributes().then(user => {
      this.log('copyObject => Signed in user: ' + user.username);
      this.log("copyObject => cognitoId: " + AppConstants.COGNITOID);
      return this.getCurrentSession()
        .then(session => {
          this.initS3Client(session, bucketName);
          //TODO: URL-Encode copySource
          const copySource = bucketName+'/'+source
          return this.s3Client?.send(new CopyObjectCommand({
            Bucket: bucketName,
            CopySource: copySource,
            Key: this.createKey(destPath, destName)
          })).then(() => {
            this.log("copyObject => success!");
            return this.getObjects(bucketName, destPath);
          }).catch((e) => this.logError('copyObject => failed: ' + e));
        }).catch(error => this.logError("copyObject => No session. Cannot create s3Client: " + error));
    }).catch(error => this.logError("copyObject => No user. Cannot create s3Client: " + error));
  }

  async putObject(bucketName: string, path: string, file?: File): Promise<any> {
    let fileName: string = "";
    if (file) fileName = file.name;
    this.log("putObject => with file name " + fileName + " in bucket: " + bucketName + " in directory " + path);
    return fetchUserAttributes().then(user => {
      this.log('putObject => Signed in user: ' + user.username);
      this.log("putObject => cognitoId: " + AppConstants.COGNITOID);
      return this.getCurrentSession()
        .then(session => {
          this.initS3Client(session, bucketName);
          return this.s3Client?.send(new PutObjectCommand({
            Bucket: bucketName,
            Key: this.createKey(path, fileName),
            Body: file
          })).then(() => {
            this.log("putObject => success!");
            if (file) return this.getObjects(bucketName, path);
            else //Folder created, so cut the folder itself
              return this.getObjects(bucketName, path.substring(0, path.lastIndexOf("/")));
          }).catch((e) => this.logError('putObject => Put object failed: ' + e));
        }).catch(error => this.logError("putObject => No session. Cannot create s3Client: " + error));
    }).catch(error => this.logError("putObject => No user. Cannot create s3Client: " + error));
  }

  async deleteObject(bucketName: string, path: string, fileName: string): Promise<any> {
    this.log("deleteObject => for bucket: '" + bucketName + "' path '" + path + "' fileName '" + fileName + "'");
    return fetchUserAttributes().then(() => {
      return this.getCurrentSession()
        .then(session => {
          this.initS3Client(session, bucketName);
          const key: string = this.createKey(path, fileName);
          this.log("deleteObject => created key: '" + key);
          return this.s3Client?.send(new ListObjectVersionsCommand({
            Bucket: bucketName,
            Prefix: path
          })).then(listObjVersionsCommandOutput => {
            const versions: ObjectVersion[] | undefined = listObjVersionsCommandOutput.Versions;
            if (versions !== undefined) {
              const num: number = versions.length;
              this.log("deleteObject => number of versions: " + num);
              versions.forEach(version => {
                this.log("deleteObject => version key: " + version.Key)
                if (version.Key === key) {
                  this.log('deleteObject => with key: ' + version.Key);
                  this.log('deleteObject => with versionId: ' + version.VersionId);
                  this.s3Client?.send(new DeleteObjectCommand({
                    Bucket: bucketName,
                    Key: key,
                    VersionId: version.VersionId
                  })).then(() => {
                    this.log("deleteObject => success for " + path + "/" + fileName);
                    return;
                  }).catch((e) => this.logError('deleteObject => Delete object failed: ' + e));
                }
              });
            }
            if (fileName.length > 0) return this.getObjects(bucketName, path);
            else return this.getObjects(bucketName, path.substring(0, path.lastIndexOf("/") + 1))
          }).catch(error => this.logError("deleteObject => Got no versions. Error: " + error));
        }).catch(error => this.logError("deleteObject => No session. Cannot create s3Client: " + error));
    }).catch(error => this.logError("deleteObject => No user. Cannot create s3Client: " + error));
  }

  async getObject(bucketName: string, fileName: string): Promise<any> {
    this.log("getObject => for bucket " + bucketName + " and key name " + fileName);
    return fetchUserAttributes().then(() => {
      return this.getCurrentSession()
        .then(session => {
          this.initS3Client(session, bucketName);
          return this.s3Client?.send(new GetObjectCommand({
            Bucket: bucketName,
            Key: fileName
          })).then(object => {
            this.log("getObject => success! Content length is " + object.ContentLength);
            this.log("getObject => success! MIME type is " + object.ContentType);
            this.log("getObject => success! Whole object " + object);
            return object;
          }).catch((e) => this.logError('getObject => Get object failed: ' + e));
        }).catch(error => this.logError("getObject => No session. Cannot create s3Client: " + error));
    }).catch(error => this.logError("getObject => No user. Cannot create s3Client: " + error));
  }

  private initS3Client(session: AuthSession, bucketName: string) {
    const jwt = this.cognitoService.getJWT(session).toString()
    //this.log('initS3Client => Session valid: ' + session.isValid());
    //let jwtData = jwt.split('.')[1];
    //let decodedJwtJsonData = window.atob(jwtData);
    //this.log("initS3Client => decodedJwtJsonData: " + decodedJwtJsonData);
    const loginData = {
      [AppConstants.COGNITOID]: jwt,
    };
    this.log("initS3Client => Create s3 client");
    this.s3Client = new S3Client({
      region: AppConstants.REGION,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: AppConstants.REGION },
        accountId: AppConstants.ACCOUNTID,
        customRoleArn: AppConstants.CUSTOMROLEARNPREFIX + bucketName,
        identityPoolId: AppConstants.IDENTITYPOOLID,
        logins: loginData
      })
    });
  }

  private async getCurrentSession(): Promise<AuthSession> {
    const session: AuthSession = await fetchAuthSession();
    return session;
  }

  private createKey(path: string, fileName: string): string {
    if (path.length > 0) return path + DELIMITER + fileName;
    else return fileName;
  }

  private log(message: string) {
    this.loggingService.log(`S3Service: ${message}`);
  }

  private logError(errorMessage: string) {
    this.loggingService.logError(`S3Service: ${errorMessage}`);
  }



}



