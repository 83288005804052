import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18n } from 'aws-amplify/utils';
import { CognitoService, LoggingService, ToastService } from '@inspiring-health/mea-commons';
import { exhaustMap, of, switchMap } from 'rxjs';
import packageJson from '../../package.json';
import { ProjectService } from './services/project.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class AppComponent implements OnInit {
  public version: string = packageJson.version;
  title = 'MEA Data Exchange';

  constructor(private loggingService: LoggingService, private toastService: ToastService,
    private translate: TranslateService, private projectService: ProjectService, 
    private cognitoService: CognitoService) {
    translate.addLangs(['de', 'en']);
    translate.setDefaultLang('en');
    let lang = translate.getBrowserLang();
    this.log(`browser lang is ${lang}`)
    if ((lang === undefined) || ((lang !== 'en') && (lang !== 'de'))) {
      lang = 'en';
    }
    translate.use(lang);
    I18n.setLanguage(lang);
  }

  ngOnInit() {
    this.log('ngOnInit => URL: ' + window.location.href);
    const path: string = window.location.href.substring(window.location.href.indexOf("/data-exchange", 0), undefined)
    this.log('ngOnInit => Path:' + path);
    const urlRegexp = /^\/data-exchange\/#\/epic\/([0-9])+\/name\/(.+)$/g
    let match : RegExpMatchArray | null = path.match(urlRegexp)
    if (match) { //handle external call
      this.projectService.calledFromOtherApp = true
      const fields = path.split('/')
      const epicIdStr = fields[4]
      this.log('ngOnInit => epicId:' + epicIdStr);
      const epicId: number = Number(epicIdStr)
      this.cognitoService.getMeaDXGroup(epicId).pipe(exhaustMap(group => {
        this.log("ngOnInit => group name is " + group)
        if (group.length > 0) {
          this.projectService.groupForExternalProject = group
          const epicName = fields[6]
          this.log('ngOnInit => epicName:' + epicName);
          const decodedEpicName = decodeURIComponent(epicName)
          this.log('ngOnInit => decodedEpicName:' + decodedEpicName);
          this.projectService.externalProjectName = decodedEpicName
        }
        return of(group)
      }), exhaustMap(group => {
        this.log("ngOnInit => group name still is " + group)
        if (group.length === 0) {
          this.logError("Current user has no group/right for epic/project access")
          return this.translate.get('APP.NOACCESSRIGHT')
        } else {
          return of("")
        } 
      }), switchMap(translation => {
        this.log("ngOnInit => translation for toast is " + translation)
        if (translation.length > 0) {
          this.toastService.showError(translation);
        }
        return of(translation)
      })).subscribe({
        next: translation => {
          this.log("ngOnInit in subscribe next. Translation is " + translation);
        },
        error: error => {
          this.logError("ngOnInit in subscribe error " + error);
        },
        complete: () => {
          this.log("ngOnInit in subscribe completed");
        }
      })
    } else {
      this.log("No external call")
      this.projectService.calledFromOtherApp = false
      this.projectService.externalProjectName = ""
    }
    
  }

  ngOnDestroy() {

  }

  private log(message: string) {
    this.loggingService.log(`AppComponent: ${message}`);
  }

  private logError(errorMessage: string) {
    this.loggingService.logError(`AppComponent: ${errorMessage}`);
  }
}
