<mea-header [title]="title"></mea-header>
<main id="main">
  <mea-login-dialog>
    <ng-template #authenticatedTemplate>
      <mea-default-navigation></mea-default-navigation>
      <mea-toast aria-live="polite" aria-atomic="true"></mea-toast>
      <app-projects></app-projects>
    </ng-template>
  </mea-login-dialog>
</main>
<mea-footer [version]="version"></mea-footer>