import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Project } from '../../models/project.model';
import { ProjectService } from '../../services/project.service';
import { LoggingService, SortableHeaderDirective, SortDirection, SortEvent } from '@inspiring-health/mea-commons';

export type ProjectColumn = keyof Project;

const compare = (v1: string | Date | boolean, v2: string | Date | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})

export class ProjectsComponent implements OnInit {

  projects: Project[] = [];
  selectedProject?: Project;

  @ViewChildren(SortableHeaderDirective) headers?: QueryList<SortableHeaderDirective<ProjectColumn>>;

  constructor(private loggingService: LoggingService, private projectService: ProjectService) { }

  calledFromExtern(): boolean {
    this.log("calledFromExtern => " + this.projectService.calledFromOtherApp)
    return this.projectService.calledFromOtherApp
  }

  ngOnInit(): void {
    this.retrieveProjects();
    if (this.calledFromExtern() && this.projects.length === 1) {
      this.onSelect(this.projects[0])
    }
  }

  onSelect(project: Project): void {
    this.log('Selected Project: ' + project.name);
    this.selectedProject = project;
  }

  onSort({ column, direction }: SortEvent<ProjectColumn>) {
    this.log("onSort with col '" + column + "' direction '" + direction + "'");
    // resetting other headers
    if (this.headers !== undefined) {
      this.headers.forEach(header => {
        if (header.sortable !== column) {
          header.reset()
        }
      });
    }
    // sorting projects
    if (direction === undefined || column === undefined) {
      this.retrieveProjects();
    } else {
      this.projects.sort((a, b) => {
        const result = compare(a[column], b[column]);
        return direction === SortDirection.ASCENDING ? result : -result;
      });
    }
  }

  private retrieveProjects() {
    this.log("RetrieveProjects => subscribe to service call");
    this.projectService.retrieveProjects().subscribe(p => this.projects = p);
  }

  private log(message: string) {
    this.loggingService.log(`ProjectsComponent: ${message}`);
  }

  private logError(errorMessage: string) {
    this.loggingService.logError(`ProjectsComponent: ${errorMessage}`);
  }
}
