import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify } from 'aws-amplify';
import { AppConstants } from './app/shared/app.constants';

if (environment.production) {
  enableProdMode();
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: AppConstants.CLIENTID,
      userPoolId: AppConstants.USERPOOLID
    }
  }
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
