import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ProjectsComponent } from './components/projects/projects.component';


const routes: Routes = [
  { path: 'home', component: AppComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
  { path: 'epic/**', component: ProjectsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
