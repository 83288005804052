import { Component, Directive, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { LoggingService } from '@inspiring-health/mea-commons';
import { Project } from '../../models/project.model';
import { ProjectService } from '../../services/project.service';

export type ProjectSortColumn = keyof Project | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | Date | boolean, v2: string | Date | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface ProjectSortEvent {
  column: ProjectSortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortableProject]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})

export class ProjectsSortableHeader {

  @Input() sortableProject: ProjectSortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sortProject = new EventEmitter<ProjectSortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sortProject.emit({ column: this.sortableProject, direction: this.direction });
  }
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})

export class ProjectsComponent implements OnInit {

  projects: Project[] = [];
  selectedProject?: Project;

  @ViewChildren(ProjectsSortableHeader) headers?: QueryList<ProjectsSortableHeader>;

  constructor(private loggingService: LoggingService, private projectService: ProjectService) { }

  calledFromExtern(): boolean {
    this.log("calledFromExtern => " + this.projectService.calledFromOtherApp)
    return this.projectService.calledFromOtherApp
  } 

  ngOnInit(): void {
    this.retrieveProjects();
    if (this.calledFromExtern() && this.projects.length === 1) {
      this.onSelect(this.projects[0])
    }
  }

  onSelect(project: Project): void {
    this.log('Selected Project: ' + project.name);
    this.selectedProject = project;
  }

  onSort({ column, direction }: ProjectSortEvent) {
    this.log("onSort with col '" + column + "' direction '" + direction + "'");
    // resetting other headers
    if (this.headers !== undefined) {
      this.headers.forEach(header => {
        if (header.sortableProject !== column) {
          header.direction = '';
        }
      });
    }
    // sorting projects
    if (direction === '' || column === '') {
      this.retrieveProjects();
    } else {
      this.projects.sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  private retrieveProjects() {
    this.log("RetrieveProjects => subscribe to service call");
    this.projectService.retrieveProjects().subscribe(p => this.projects = p);
  }

  private log(message: string) {
    this.loggingService.log(`ProjectsComponent: ${message}`);
  }

  private logError(errorMessage: string) {
    this.loggingService.logError(`ProjectsComponent: ${errorMessage}`);
  }
}
