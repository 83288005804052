import { environment } from "src/environments/environment";

export class AppConstants {
    public static REGION: string = environment.region;
    public static USERPOOLID: string = environment.userpoolid;
    public static IDENTITYPOOLID: string = environment.identitypoolid;
    public static CLIENTID: string = environment.clientid;
    public static ACCOUNTID: string = environment.accountid;
    public static CUSTOMROLEARNPREFIX: string = environment.customRoleArnPrefix;
    public static COGNITOID: string = 'cognito-idp.' + environment.region + '.amazonaws.com/' + environment.userpoolid;
    public static PROJECTNAMEPREFIX: string = environment.projectNamePrefix;
    public static PROJECTNAMEPREFIXLENGTH: number = environment.projectNamePrefix.length;
    public static PROJECTWITHFOLDERMARKER: string = "folder-";
}