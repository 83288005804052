<div class="modal-header modal-header-ih">
    <h4 class="modal-title modal-title-ih" data-cy="document-overwrite-dialog-title">{{title}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('CROSS_CLICK')"></button>
  </div>
  <div class="modal-body modal-body-ih">
    
    <div class="modal-footer modal-footer-ih">
      <button type="button" class="btn btn-outline-dark submit-ih" [ngbTooltip]="'DOCUMENTS.OVERWRITEDIALOG.CANCELBUTTONHINT' | translate"
        (click)="activeModal.dismiss('CANCEL_CLICK')" data-cy="document-overwrite-cancel-button">{{cancelButtonText}}</button>
      <button type="button" class="btn btn-outline-dark submit-ih" [ngbTooltip]="'DOCUMENTS.OVERWRITEDIALOG.OKBUTTONHINT' | translate"
        (click)="activeModal.close('OK_CLICK')" data-cy="document-overwrite-ok-button">{{okButtonText}}</button>
        <button type="button" class="btn btn-outline-dark submit-ih" [ngbTooltip]="'DOCUMENTS.OVERWRITEDIALOG.NOBUTTONHINT' | translate"
        (click)="activeModal.close('NO_CLICK')" data-cy="document-overwrite-no-button">{{noButtonText}}</button>
    </div>
  </div>
