<h2 *ngIf="!calledFromExtern()" container="body" [ngbTooltip]="'SORTHINT' | translate">{{'PROJECTS.PROJECTS' |
  translate}}</h2>

<table *ngIf="!calledFromExtern()" id="projectTable" class="table table-ih table-striped projects">
  <thead class=".thead-light">
    <tr [ngbTooltip]="'PROJECTS.SORT' | translate" container="body">
      <th scope="col" sortable="name" (sort)="onSort($event)">{{'PROJECTS.PROJECTNAME' | translate}}</th>
      <th scope="col" sortable="subProject" (sort)="onSort($event)">{{'PROJECTS.SUBPROJECT' | translate}}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr id="project-row-{{project.subProject}}-{{project.name}}" *ngFor="let project of projects"
      [class.selected]="project === selectedProject" (click)="onSelect(project)"
      [ngbTooltip]="'PROJECTS.SELECTTOSHOWCONTENT' | translate" container="body">
      <td id="project-cell-name-{{project.subProject}}-{{project.name}}">{{project.displayName}}</td>
      <td id="project-cell-id-{{project.subProject}}-{{project.name}}">{{project.subProject}}</td>
    </tr>
  </tbody>
</table>

<app-documents [project]="selectedProject"></app-documents>