import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowsAlt, faDownload, faEdit, faFolderPlus, faSortDown, faTimes, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';

import { ConfirmationDialogComponent, LocalizedDatePipe, LoggingService, SortableHeaderDirective, SortDirection, SortEvent, ToastService, TranslatableToastInput } from '@inspiring-health/mea-commons';

import { Document } from '../../models/document.model';
import { Project } from '../../models/project.model';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, concatMap, exhaustMap, forkJoin, from, of, switchMap, tap, throwError, toArray } from 'rxjs';
import { DocumentService } from '../../services/document.service';
import { DocumentsBaseComponent } from '../../shared/documents-base.component';
import { FolderDialogComponent } from '../folder-dialog/folder-dialog.component';
import { MoveDialogComponent } from '../move-dialog/move-dialog.component';
import { OverwriteDialogComponent } from '../overwrite-dialog/overwrite-dialog.component';
import { RenameDialogComponent } from '../rename-dialog/rename-dialog.component';

export type DocumentColumn = keyof Document;

const compare = (v1: string | Date | number | boolean, v2: string | Date | number | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent extends DocumentsBaseComponent implements OnInit {

  private readonly FILENAMES_MAXLENGTH = 50

  loading: boolean = false;
  selectAll: boolean = false
  fileNames: string = '';
  fileNamesTooltip: string = '';
  selectedFiles?: File[];

  allDocuments: Document[] = [];
  nameFilter: string = ''
  dateFilter: string = ''
  selectedFilter: boolean = false

  @Input({ required: true }) project?: Project;

  readonly deleteIcon: IconDefinition = faTrashAlt;
  readonly uploadIcon: IconDefinition = faUpload;
  readonly downloadIcon: IconDefinition = faDownload;
  readonly folderPlusIcon: IconDefinition = faFolderPlus;
  readonly renameIcon: IconDefinition = faEdit;
  readonly moveIcon: IconDefinition = faArrowsAlt;
  readonly clearIcon: IconDefinition = faTimes;

  readonly defaultSortDirection = SortDirection.DESCENDING
  readonly defaultSortColumn = "modificationDate"

  @ViewChildren(SortableHeaderDirective) headers?: QueryList<SortableHeaderDirective<DocumentColumn>>


  constructor(loggingService: LoggingService, documentService: DocumentService,
    private modalService: NgbModal, public translate: TranslateService, private toastService: ToastService,
    private datePipe: LocalizedDatePipe) {
    super(loggingService, documentService)
  }

  ngOnInit(): void {
    this.log("ngOnInit selected project: " + this.project?.name);
    if (this.project) {
      this.documentService.retrieveDocuments(this.path.length, this.project, this.project.subProject, false).subscribe(s => {
        this.documents = s;
        this.allDocuments = [...this.documents]
        this.init()
      });
    }
  }

  private initDocsDefaultSort(docs: Document[]): void {
    this.documents = docs;
    this.allDocuments = [...this.documents]
    this.init()
  }

  private initDocsCurrentFilterSort(docs: Document[]): void {
    this.documents = docs;
    this.allDocuments = [...this.documents]
    this.onFilter()
  }

  private init(): void {
    this.fileNames = ''
    this.fileNamesTooltip = ''
    this.unselectAll()
    this.sortByDefault();
    this.nameFilter = ''
    this.dateFilter = ''
    this.selectedFilter = false
  }

  ngOnChanges() {
    this.log("ngOnChanges selected project: " + this.project?.name);
    this.path = [];
    this.retrieveDocuments(this.project!).subscribe(docs => {
      this.initDocsDefaultSort(docs)
    })
  }

  onFilesSelected(event: Event) {
    this.log("onFilesSelected => called")
    const input = event.target as HTMLInputElement;
    this.fileNames = ""
    this.fileNamesTooltip = ""
    if (input.files) {
      this.selectedFiles = Array.from(input.files);
      this.selectedFiles.forEach(file => {
        this.log("onFilesSelected => File: " + file.name)
        if (this.fileNames.length < this.FILENAMES_MAXLENGTH) this.fileNames = this.fileNames + file.name + " "
        else this.fileNamesTooltip = this.fileNamesTooltip + file.name + " "
      });
      if (this.fileNames.length >= this.FILENAMES_MAXLENGTH) this.fileNames += "..."
    }
    input.value = ''
  }

  showFilesTooltip(): string | null {
    return this.fileNames.length >= this.FILENAMES_MAXLENGTH ? this.fileNamesTooltip : null
  }
 
  onSort({ column, direction }: SortEvent<DocumentColumn>) {
    this.log("onSort => column " + column + " with direction " + direction)
    // resetting other headers
    this.headers?.forEach((header) => {
      if (header.sortable !== column) {
        header.reset()
      }
    });

    // sorting documents
    if (direction === undefined || column === undefined) {
      this.log("onSort => nothing to sort NOP")
    } else {
      this.log("onSort => start separate folder and documents")
      let folders: Document[] = [];
      let files: Document[] = [];
      this.documents.forEach(d => {
        if (d.isFolder) {
          folders.push(d);
        } else {
          files.push(d);
        }
      });
      
      this.log("onSort => folder")
      folders.sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === SortDirection.ASCENDING ? res : -res;
      });
      this.log("onSort => documents")
      files.sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === SortDirection.ASCENDING ? res : -res;
      });
      this.documents = folders.concat(files);
    }
  }

  onSelectPath(project: Project, document: Document) {
    this.log("onSelectPath: " + document.displayName);
    this.selectAll = false
    super.retrieveOnSelectPath(project, document).subscribe(docs => {
      this.initDocsDefaultSort(docs)
    })

  }

  onSelectRoot(project: Project) {
    this.log("onSelectRoot: " + project.name);
    this.selectAll = false
    super.retrieveOnSelectRoot(project).subscribe(docs => {
      this.initDocsDefaultSort(docs)
    })

  }

  onClick(document: Document) {
    if (!document.isFolder) {
      this.download(document);
    } else {
      this.listSubDir(document);
    }
  }


  onFilter() {
    this.log("onFilter called");
    let resultSet: Document[] = [...this.allDocuments]
    this.log("onFilter initialized result set " + JSON.stringify(resultSet));
    if (this.selectedFilter) {
      this.log("onFilter with " + this.selectedFilter);
      resultSet = resultSet.filter(doc => doc.isSelected)
    }
    if (this.nameFilter.length > 0) {
      this.log("onFilter with " + this.nameFilter);
      resultSet = resultSet.filter(doc => doc.displayName.toLowerCase().includes(this.nameFilter.toLocaleLowerCase()))
    }
    if (this.dateFilter.length > 0) {
      this.log("onFilter with " + this.dateFilter);
      resultSet = resultSet.filter(doc => this.datePipe.transform(doc.modificationDate).toString().toLowerCase().includes(this.dateFilter.toLowerCase()))
    }
    this.log("onFilter result set " + JSON.stringify(resultSet));
    this.documents = resultSet
    this.checkAndMarkSelectAllCheckbox()
    this.repeatCurrentSort()
  }

  clearNameFilter() {
    this.nameFilter = ''
    this.onFilter()
  }

  clearDateFilter() {
    this.dateFilter = ''
    this.onFilter()
  }

  toggleSelectAll() {
    this.documents.forEach((doc) => {
      if (!doc.isFolder) doc.isSelected = this.selectAll
    })
  }

  private unselectAll() {
    this.selectAll = false
    this.documents.forEach(doc => {
      if (!doc.isFolder) doc.isSelected = false
    })
  }

  toggleSelect(doc: Document) {
    this.log("toggleSelect => " + JSON.stringify(doc))
    this.checkAndMarkSelectAllCheckbox()
    if (this.selectedFilter) this.onFilter()
  }

  private checkAndMarkSelectAllCheckbox() {
    this.selectAll = this.isAllSelected()
  }

  isAllSelected(): boolean {
    return this.documents.every(doc => doc.isFolder || doc.isSelected);
  }

  get selectedDocuments(): Document[] {
    return this.documents.filter((doc) => doc.isSelected)
  }

  get selectCount(): number {
    return this.documents.filter(doc => doc.isSelected).length ?? 0
  }

  upload() {
    this.log("upload => called for " + this.fileNames + this.fileNamesTooltip);
    let isValidName: number;
    if (this.project && this.selectedFiles && this.selectedFiles.length > 0) {
      forkJoin(
        this.selectedFiles.map(file => {
          if (this.isSameNameInDocs(file.name, this.documents)) {
            const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTUPLOADFILEEXISTS.TEXT', params: { documentname: file.name } };
            const errorToastTranslatableTitle: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTUPLOADFILEEXISTS.TITLE' };
            this.logError("upload => " + errorToastTranslatableText.key + ", " + errorToastTranslatableText.params);
            this.toastService.showErrorCloseOnClick(errorToastTranslatableText, errorToastTranslatableTitle);
            return of(this.documents)
          }
          isValidName = this.documentService.isValidObjectKey(file.name)
          if (isValidName === 0) {
            return this.documentService.uploadDokument(this.path.length, this.project!, this.getPath(this.project!, this.path), file)
          } else {
            this.handleNamingError(isValidName)
            if (this.path.length > 0) {
              return this.documentService.retrieveDocuments(this.path.length, this.project!, this.path[this.path.length - 1].fileName, false)
            }
            else {
              return this.documentService.retrieveDocuments(0, this.project!, this.project!.subProject, false)
            }
          }
        })
      ).subscribe(results => {
        this.log("upload => finished. Result: " + JSON.stringify(results));
        this.selectAll = false
        const docs = results.reduce((longest, current) => {
          return (current.length > longest.length) ? current : longest
        }, [])
        if (docs) {
          this.initDocsCurrentFilterSort(docs)
        }
      })
    } else {
      this.logError("upload => no file");
      this.handleNamingError(2)
    }
    this.fileNames = '';
    this.selectedFiles = undefined;
  }

  private handleNamingError(errorCode: number) {
    if (errorCode === 3) {
      const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTINVALIDNAME.TEXT' };
      const errorToastTranslatableTitle: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTINVALIDNAME.TITLE' };
      this.logError("handleNamingError => " + errorToastTranslatableText.key);
      this.toastService.showErrorCloseOnClick(errorToastTranslatableText, errorToastTranslatableTitle);
    } else if (errorCode === 1) {
      const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMETOLONG.TEXT' };
      const errorToastTranslatableTitle: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMETOLONG.TITLE' };
      this.logError("handleNamingError => " + errorToastTranslatableText.key);
      this.toastService.showErrorCloseOnClick(errorToastTranslatableText, errorToastTranslatableTitle);
    } else if (errorCode === 2) {
      const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMEIS0.TEXT' };
      const errorToastTranslatableTitle: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMEIS0.TITLE' };
      this.logError("handleNamingError => " + errorToastTranslatableText.key);
      this.toastService.showErrorCloseOnClick(errorToastTranslatableText, errorToastTranslatableTitle);
    }
  }

  overwrite(toOverwrite: Document, overwriteWith: Document, currentPath: Document[], originalDoc: Document) {
    this.log("overwrite => called with " + JSON.stringify(toOverwrite) + " to overwrite with " + JSON.stringify(overwriteWith))
    this.log("overwrite => called with currentPath: " + JSON.stringify(currentPath))
    const modalRef = this.modalService.open(OverwriteDialogComponent)
    modalRef.componentInstance.toOverwrite = toOverwrite
    modalRef.componentInstance.overwriteWith = overwriteWith
    modalRef.componentInstance.currentPath = Array.from(currentPath)

    this.translate.get('DOCUMENTS.OVERWRITEDIALOG.CANCELBUTTON').subscribe(translation => {
      modalRef.componentInstance.cancelButtonText = translation
    })
    this.translate.get('DOCUMENTS.OVERWRITEDIALOG.OKBUTTON').subscribe(translation => {
      modalRef.componentInstance.okButtonText = translation
    })
    this.translate.get('DOCUMENTS.OVERWRITEDIALOG.NOBUTTON').subscribe(translation => {
      modalRef.componentInstance.noButtonText = translation
    })
    this.translate.get('DOCUMENTS.OVERWRITEDIALOG.TITLE', { documentname: overwriteWith.displayName }).subscribe(translation => {
      modalRef.componentInstance.title = translation
    })
    //set result handlers
    modalRef.result.then((result) => {
      this.log("overwriteDialog => closed with " + result)
      //OK_CLICK or NO_CLICK
      if (result === 'NO_CLICK') {
        this.appendNextVersion(overwriteWith)
      }
      const currentPath: Document[] = modalRef.componentInstance.currentPath
      this.log("overwriteDialog => path: " + JSON.stringify(currentPath))
      const targetFolder = currentPath[currentPath.length - 1]
      this.log("overwriteDialog => targetFolder: " + JSON.stringify(targetFolder))
      if (!overwriteWith.isFolder) {
        this.moveDocumentHandler(targetFolder, overwriteWith, originalDoc);
      } else {
        this.moveFolderHandler(targetFolder, overwriteWith, originalDoc);
      }
    }, (reason) => { this.log("overwriteDialog => closed with " + reason); }
    );
  }

  private appendNextVersion(doc: Document) {
    this.log("appendNextVersion => input: " + JSON.stringify(doc))
    if (doc.isFolder) {
      let name = this.cutFileName(doc.fileName)
      doc.fileName = name + "_1/"
      doc.displayName = doc.displayName + "_1"
    } else {
      const urlRegexp = /([\w-]+)\.[a-zA-Z]{3}/g
      let match: RegExpMatchArray | null = doc.displayName.match(urlRegexp)
      if (match) {
        const extension = doc.displayName.slice(-3)
        const withoutExtension = doc.displayName.slice(0, -4)
        doc.displayName = withoutExtension + "_1." + extension
        const fileNameWithoutExtension = doc.fileName.slice(0, -4)
        doc.fileName = fileNameWithoutExtension + "_1." + extension
      } else {
        doc.fileName = doc.fileName + "_1"
        doc.displayName = doc.displayName + "_1"
      }
    }
    this.log("appendNextVersion => changed names are " + JSON.stringify(doc))
  }

  move(doc: Document) {
    this.log("move => clicked for file name " + doc.fileName);
    const modalRef = this.modalService.open(MoveDialogComponent)
    const copiedDoc: Document = { ...doc }
    modalRef.componentInstance.documents = undefined
    modalRef.componentInstance.documentToMove = copiedDoc
    modalRef.componentInstance.targetFolder = this.path.at(-1)
    modalRef.componentInstance.project = this.project
    modalRef.componentInstance.path = Array.from(this.path)
    this.translate.get('DOCUMENTS.MOVEDIALOG.NAME').subscribe(translation => {
      modalRef.componentInstance.label = translation
    })
    this.translate.get('DOCUMENTS.MOVEDIALOG.SELECTTARGET').subscribe(translation => {
      modalRef.componentInstance.selecttarget = translation
    })
    this.translate.get('DOCUMENTS.MOVEDIALOG.CANCELBUTTON').subscribe(translation => {
      modalRef.componentInstance.cancelButtonText = translation
    })
    this.translate.get('DOCUMENTS.MOVEDIALOG.OKBUTTON').subscribe(translation => {
      modalRef.componentInstance.okButtonText = translation
    })
    this.translate.get('DOCUMENTS.MOVEDIALOG.TITLE', { documentname: copiedDoc.displayName }).subscribe(translation => {
      modalRef.componentInstance.title = translation
    })
    //set result handlers
    modalRef.result.then(() => {
      this.log("moveDialog => closed with yes");
      let targetFolderNameWithoutSlash: string = ""
      if (modalRef.componentInstance.targetFolder) {
        targetFolderNameWithoutSlash = this.documentService.cutLastSlash(modalRef.componentInstance.targetFolder.fileName)
      } else {
        const rootDir: Document = {
          displayName: "",
          fileName: "",
          isFolder: true,
          level: 0,
          modificationDate: new Date(),
          isSelected: false
        }
        modalRef.componentInstance.targetFolder = rootDir
      }
      if (targetFolderNameWithoutSlash === this.documentService.retrieveParentDir(doc.fileName)) {
        const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.MOVEDIALOG.ERRORFILEISSAME' };
        this.logError("moveFolderHandler => " + errorToastTranslatableText.key);
        this.toastService.showError(errorToastTranslatableText);
      } else if (modalRef.componentInstance.targetFolder.fileName.localeCompare(doc.fileName) === 0) {
        const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.MOVEDIALOG.ERRORINTOSELF' };
        this.logError("moveFolderHandler => " + errorToastTranslatableText.key);
        this.toastService.showError(errorToastTranslatableText);
      } else if (this.project !== undefined) {
        this.log("moveDialog => doc to move attributes: " + JSON.stringify(modalRef.componentInstance.documentToMove));
        this.log("moveDialog => target folder: " + JSON.stringify(modalRef.componentInstance.targetFolder))
        const docToOverwrite = this.isSameNameInDocs(modalRef.componentInstance.documentToMove.displayName, modalRef.componentInstance.documents)
        if (docToOverwrite) {
          if (copiedDoc.isFolder) {
            const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.MOVEDIALOG.ERRORFOLDEREXISTS' };
            const errorToastTranslatableTitle: TranslatableToastInput = { key: 'DOCUMENTS.MOVEDIALOG.ERRORTITLE' };
            this.logError("moveDialog => " + errorToastTranslatableText.key);
            this.toastService.showErrorCloseOnClick(errorToastTranslatableText, errorToastTranslatableTitle);
            this.loading = false;
          } else {
            this.log("moveDialog => overwrite doc: " + docToOverwrite.displayName)
            this.overwrite(docToOverwrite, copiedDoc, modalRef.componentInstance.path, doc)
          }
        } else {
          let currentPath: Document[] = modalRef.componentInstance.path
          this.log("moveDialog => path : " + JSON.stringify(currentPath))
          if (!doc.isFolder) {
            this.moveDocumentHandler(modalRef.componentInstance.targetFolder, copiedDoc, doc);
          } else {
            this.moveFolderHandler(modalRef.componentInstance.targetFolder, copiedDoc, doc);
          }
        }
      }
    }, () => { this.log("moveDialog => closed with no"); }
    );
  }

  isDocInDocs(): boolean {
    return this.documents.some(doc => {
      if (!doc.isFolder) {
        return true
      }
      return false
    })
  }

  private isSameNameInDocs(docDisplayName: string, docs: Document[]): Document | undefined {
    let docWithSameName: Document | undefined = undefined
    docs.forEach((d: Document) => {
      if (docDisplayName === d.displayName) docWithSameName = d
    });
    return docWithSameName
  }

  private moveDocumentHandler(targetFolder: Document, doc: Document, originalDoc: Document) {
    //if targetFolder is undefined move to root is executed
    this.loading = true
    let targetFolderName: string = ""
    let targetFolderLevel: number = 0
    if (targetFolder) {
      targetFolderName = targetFolder.fileName
      targetFolderLevel = targetFolder.level
    }
    this.log("moveDocumentHandler => target folder name: " + targetFolderName);
    this.log("moveDocumentHandler => target folder level: " + targetFolderLevel);
    this.log("moveDocumentHandler => document name: " + doc.fileName);
    this.log("moveDocumentHandler => original document name: " + originalDoc.fileName);
    if (targetFolderName.length + doc.fileName.length < 1025) {
      this.documentService.copyDocument(
        targetFolderLevel, this.project!, originalDoc.fileName, targetFolderName, doc.displayName).pipe(
          exhaustMap(() => {
            return this.documentService.deleteDocument(originalDoc.level, this.project!, originalDoc);
          }), exhaustMap(() => {
            this.log("moveDocumentHandler => document deleted")
            if (this.path.length > 0) {
              return this.documentService.retrieveDocuments(this.path.length, this.project!, this.path[this.path.length - 1].fileName, false)
            }
            else {
              return this.documentService.retrieveDocuments(0, this.project!, this.project!.subProject, false)
            }
          })).subscribe({
            next: docs => {
              this.log("moveDocumentHandler => docs retrieved after copy and delete: " + JSON.stringify(docs))
              this.initDocsCurrentFilterSort(docs)
              this.unselectAll()
            },
            error: error => {
              this.logError("moveDocumentHandler => error: " + error.message)
              this.loading = false
            },
            complete: () => {
              this.log("moveDocumentHandler => completed")
              this.loading = false 
            }
          }
          );
    } else {
      const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMETOLONG.TEXT' };
      const errorToastTranslatableTitle: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMETOLONG.TITLE' };
      this.logError("moveDocumentHandler => Error1: " + errorToastTranslatableText.key);
      this.logError("moveDocumentHandler => Error2: " + errorToastTranslatableTitle.key);
      this.toastService.showErrorCloseOnClick(errorToastTranslatableText, errorToastTranslatableTitle);
      this.loading = false
    }
  }

  private moveFolderHandler(targetFolder: Document, folder: Document, originalFolder: Document) {
    this.loading = true;
    this.log("moveFolderHandler => called with: " + folder.fileName + " to target " + targetFolder.fileName);
    this.log("moveFolderHandler => folder name: " + folder.fileName);
    this.log("moveFolderHandler => called with original folder: " + originalFolder.fileName);

    this.moveFolder(folder, targetFolder, originalFolder).pipe(exhaustMap(() => {
      this.log("moveFolderHandler => delete folder " + originalFolder.fileName)
      return this.documentService.deleteDocument(originalFolder.level, this.project!, originalFolder)
    }), exhaustMap(() => {
      this.log("moveFolderHandler => folder deleted")
      if (this.path.length > 0) {
        return this.documentService.retrieveDocuments(this.path.length, this.project!, this.path[this.path.length - 1].fileName, false)
      }
      else {
        return this.documentService.retrieveDocuments(0, this.project!, this.project!.subProject, false)
      }
    })).subscribe({
      next: docs => {
        this.log("moveFolderHandler => docs retrieved after copy and delete: " + JSON.stringify(docs))
        this.initDocsCurrentFilterSort(docs)
        this.unselectAll()
      },
      error: error => {
        this.logError("moveFolderHandler => error: " + error.message)
        this.loading = false
      },
      complete: () => {
        this.log("moveFolderHandler => completed")
        this.loading = false;
      }
    })
  }

  private moveFolder(folder: Document, targetFolder: Document, originalFolder: Document): Observable<Document[]> {
    this.log("moveFolder => called with doc: '" + JSON.stringify(folder) + "' and target " + JSON.stringify(targetFolder))
    //if targetFolder is undefined move to root is executed
    let targetFolderName: string = ""
    let targetFolderLevel: number = 0
    if (targetFolder) {
      targetFolderName = targetFolder.fileName
      targetFolderLevel = targetFolder.level
    }
    if (targetFolderName.length + folder.displayName.length < 1024) {
      this.log("moveFolder => target folder name: " + targetFolderName);
      this.log("moveFolder => target folder level: " + targetFolderLevel);
      const newFolder: Document = {
        displayName: folder.displayName,
        fileName: targetFolderName + folder.displayName + '/',
        isFolder: true,
        level: targetFolderLevel + 1,
        modificationDate: new Date(),
        isSelected: false
      }

      this.log("moveFolder => create new folder: " + JSON.stringify(newFolder))
      return this.documentService.createFolder(newFolder.level, this.project!, newFolder).pipe(
        exhaustMap((cf: Document[]) => {
          this.log("moveFolder => new created folder: " + JSON.stringify(cf))
          return this.documentService.retrieveDocuments(originalFolder.level, this.project!, originalFolder.fileName, false)
        }), exhaustMap((docs: Document[]) => {
          this.log("moveFolder => docs retrieved in folder to move: " + JSON.stringify(docs))
          if (!docs || docs.length === 0) {
            //If originalFolder is empty we have finished, so delete it
            this.log("moveFolder => delete original folder cause no further docs to handle" + originalFolder.fileName)
            return this.documentService.deleteDocument(originalFolder.level, this.project!, originalFolder).pipe(
              exhaustMap(() => {
                this.log("moveFolder => original folder deleted")
                return docs
              })
            )
          } else {
            //Handle dirs first
            docs.sort((d1, d2) => {
              if (d1.isFolder === d2.isFolder) return 0
              else if (d1.isFolder) return -1
              else return 1
            })
          }
          return from(docs)
        }), concatMap((doc: Document) => {
          this.log("moveFolder => doc from docs retrieved: " + JSON.stringify(doc))
          this.log("moveFolder => doc filename : " + doc.fileName)
          if (doc && doc.fileName.localeCompare(originalFolder.fileName) !== 0) {
            this.log("moveFolder => handle doc: " + doc.fileName)
            if (!doc.isFolder) {
              if (newFolder.fileName.length + doc.displayName.length < 1025) {
                this.log("moveFolder => copy doc " + doc.fileName + " to folder " + newFolder.fileName)
                return this.documentService.copyDocument(doc.level, this.project!, doc.fileName, newFolder.fileName, doc.displayName).pipe(
                  exhaustMap(() => {
                    this.log("moveFolder => delete doc " + doc.fileName)
                    return this.documentService.deleteDocument(doc.level, this.project!, doc)
                  }), exhaustMap(() => {
                    return of(doc)
                  }))
              } else {
                const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMETOLONG.TEXT' };
                const errorToastTranslatableTitle: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMETOLONG.TITLE' };
                this.logError("moveFolder => " + errorToastTranslatableTitle.key);
                this.toastService.showErrorCloseOnClick(errorToastTranslatableText, errorToastTranslatableTitle);
                return throwError(() => new Error('moveFolder => object key exceeds lenght of 1024'));
              }
            } else { //doc is folder
              this.log("moveFolder => call moveFolder (recursive) with doc " + doc.fileName + " target " + newFolder.fileName)
              //In this case originalFolder === doc
              return this.moveFolder(doc, newFolder, doc).pipe(exhaustMap(() => {
                this.log("moveFolder => recursive call finished")
                return of(doc)
              }))
            }
          } else {
            this.log("moveFolder => return unhandled doc")
            return of(doc)
          }
        }),
        tap(d => this.log("moveFolder => " + d.fileName + " moved")),
        toArray()
      )
    } else {
      const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMETOLONG.TEXT' };
      const errorToastTranslatableTitle: TranslatableToastInput = { key: 'DOCUMENTS.ERRORTOASTFILENAMETOLONG.TITLE' };
      this.logError("moveFolder => " + errorToastTranslatableTitle.key);
      this.toastService.showErrorCloseOnClick(errorToastTranslatableText, errorToastTranslatableTitle);


      return throwError(() => new Error('moveFolder => object key exceeds lenght of 1024'));
    }
  }

  rename(doc: Document) {
    this.log("rename => clicked for " + doc.displayName + " and file name " + doc.fileName);
    const modalRef = this.modalService.open(RenameDialogComponent)
    modalRef.componentInstance.document = {
      displayName: doc.displayName,
      fileName: doc.fileName,
      isFolder: doc.isFolder,
      level: doc.level,
      modificationDate: doc.modificationDate
    }
    this.translate.get('DOCUMENTS.RENAMEDIALOG.NAME').subscribe(translation => {
      modalRef.componentInstance.label = translation
    })
    this.translate.get('DOCUMENTS.RENAMEDIALOG.CANCELBUTTON').subscribe(translation => {
      modalRef.componentInstance.cancelButtonText = translation
    })
    this.translate.get('DOCUMENTS.RENAMEDIALOG.OKBUTTON').subscribe(translation => {
      modalRef.componentInstance.okButtonText = translation
    })
    this.translate.get('DOCUMENTS.RENAMEDIALOG.TITLE').subscribe(translation => {
      modalRef.componentInstance.title = translation
    })
    //set result handlers
    if (!doc.isFolder) {
      this.renameDocumentHandler(modalRef, doc);
    } else {
      this.renameFolderHandler(modalRef, doc);
    }
  }

  private renameFolderHandler(modalRef: NgbModalRef, folder: Document) {
    this.log("renameFolderHandler => called with: " + folder.fileName);
    modalRef.result.then(() => {
      this.loading = true;
      const newFolderName: string = modalRef.componentInstance.document.displayName
      const isValidName: number = this.documentService.isValidObjectKey(newFolderName)
      const isExistingDoc: Document | undefined = this.isSameNameInDocs(modalRef.componentInstance.document.displayName, this.documents)
      if (isExistingDoc) {
        const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.RENAMEDIALOG.ERRORNONAME' };
        this.logError("renameFolderHandler => " + errorToastTranslatableText.key);
        this.toastService.showError(errorToastTranslatableText);
        this.loading = false;
      }
      else if (isValidName === 0) {
        this.log("renameFolderHandler => closed with yes");
        this.log("renameFolderHandler => new name is " + newFolderName);
        this.log("renameFolderHandler => old folder file name is " + folder.fileName);
        const folderLevel: number = folder.level
        this.log("renameFolderHandler => folder level is " + folderLevel);
        const newFolderPath: string = this.replaceFolderName(folder.fileName, newFolderName)
        this.log("renameFolderHandler => new folder path is " + newFolderPath);
        if (this.project !== undefined) {
          this.log("renameFolderHandler => project is " + this.project.name);
          this.documentService.retrieveDocuments(folderLevel, this.project, folder.fileName, true).pipe(
            exhaustMap((docs: Document[]) => {
              this.log("renameFolderHandler => all docs to handle: " + JSON.stringify(docs))
              docs.sort((d1, d2) => {
                if (d1.isFolder === d2.isFolder) return 0
                else if (d1.isFolder) return -1
                else return 1
              })
              return from(docs)
            }), concatMap(doc => {
              this.log("renameFolderHandler => ------------")
              this.log("renameFolderHandler => handle doc: " + JSON.stringify(doc))
              const newFilePath: string = this.replacePathInFileName(doc.fileName, folder.fileName, newFolderPath)
              this.log("renameFolderHandler => new path for doc is " + newFilePath)
              const newFolder: Document = {
                displayName: doc.displayName,
                isFolder: true,
                level: doc.level,
                fileName: newFilePath,
                modificationDate: new Date(),
                isSelected: false
              }
              if (doc.isFolder) {
                if (doc.level === folderLevel) {
                  newFolder.fileName = newFolderPath
                }
                this.log("renameFolderHandler => the folder to rename: " + JSON.stringify(newFolder))
                return this.documentService.createFolder(newFolder.level - 1, this.project!, newFolder).pipe(
                  concatMap(() => this.documentService.deleteDocument(doc.level - 1, this.project!, doc))
                )
              } else {
                const newPathForFile: string = this.cutFileName(newFilePath)
                this.log("renameFolderHandler => new path for doc is " + newFilePath);
                return this.documentService.copyDocument(doc.level, this.project!, doc.fileName, newPathForFile, doc.displayName).pipe(
                  concatMap(() => this.documentService.deleteDocument(folderLevel - 1, this.project!, doc))
                )
              }
            }), switchMap((d) => {
              return this.documentService.retrieveDocuments(folderLevel - 1, this.project!, this.getPath(this.project!, this.path), false)
            })).subscribe({
              next: docs => {
                this.log("renameFolderHandler => docs after copy and delete: " + JSON.stringify(docs))
                this.initDocsCurrentFilterSort(docs)
                this.unselectAll()
              },
              error: error => {
                this.logError("renameFolderHandler => error: " + error.message)
              },
              complete: () => {
                this.log("renameFolderHandler => completed")
                this.loading = false;
              }
            });
        }
      } else {
        this.loading = false;
        this.handleNamingError(isValidName)
      }
    }, () => {
      this.log("renameFolderHandler => closed with no");
      this.loading = false;
    })
  }

  private cutFileName(fullFileName: string): string {
    const lastIndexOfSlash: number = fullFileName.lastIndexOf('/')
    return fullFileName.substring(0, lastIndexOfSlash)
  }


  private replaceFolderName(path: string, newFolder: string): string {
    const withoutLastSlash: string = path.substring(0, path.length - 1)
    this.log("replaceFolderName => without last slash " + withoutLastSlash)
    const lastIndexOfSlash: number = withoutLastSlash.lastIndexOf('/')
    this.log("replaceFolderName => last idx of slash " + lastIndexOfSlash)
    if (lastIndexOfSlash === -1) return newFolder
    const newPath = path.substring(0, lastIndexOfSlash + 1) + newFolder
    this.log("replaceFolderName => new path " + newPath)
    return newPath
  }

  private replacePathInFileName(fileNamePath: string, oldFolderPath: string, newFolderPath: string): string {
    this.log("replacePathInFileName => called with fileNamePath " + fileNamePath + " oldFolderPath " + oldFolderPath + " newFolderPath " + newFolderPath)
    const newFileNamePath: string = fileNamePath.replace(oldFolderPath, newFolderPath + '/')
    this.log("replacePathInFileName => new file name is " + newFileNamePath)
    return newFileNamePath
  }

  //Result handler
  private renameDocumentHandler(modalRef: NgbModalRef, doc: Document) {
    modalRef.result.then(() => {
      this.loading = true;
      this.log("renameDocumentHandler => closed with yes");
      const isValidName = this.documentService.isValidObjectKey(modalRef.componentInstance.document.displayName)
      const isExistingDoc: Document | undefined = this.isSameNameInDocs(modalRef.componentInstance.document.displayName, this.documents)
      if (isExistingDoc) {
        const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.RENAMEDIALOG.ERRORNONAME' };
        this.logError("renameDocumentHandler => " + errorToastTranslatableText.key);
        this.toastService.showError(errorToastTranslatableText);
        this.loading = false;
      }
      else if (isValidName === 0) {
        this.renameDocument(doc, modalRef.componentInstance.document.displayName).subscribe({
          next: docs => {
            this.log("renameDocumentHandler => docs after copy and delete: " + JSON.stringify(docs))
            this.initDocsCurrentFilterSort(docs)
            this.unselectAll()
          },
          error: error => {
            this.logError("renameDocumentHandler => error: " + error.message)
          },
          complete: () => {
            this.log("renameDocumentHandler => completed")
            this.loading = false;
          }
        });
      } else {
        this.loading = false;
        this.handleNamingError(isValidName)
      }
    }, () => {
      this.log("renameDialog => closed with no");
      this.loading = false;
    }
    );
  }

  private renameDocument(doc: Document, name: string): Observable<Document[]> {
    if (this.project !== undefined) {
      this.log("renameDocument => with doc attributes: " + JSON.stringify(doc) + " and name " + name);
      return this.documentService.copyDocument(
        doc.level, this.project, doc.fileName, this.cutFileName(doc.fileName), name).pipe(
          exhaustMap(() => {
            this.log("renameDocument => with doc copied now delete old");
            return this.documentService.deleteDocument(doc.level - 1, this.project!, doc);
          })
        );
    } else {
      return EMPTY
    }
  }

  newFolder() {
    this.log("newFolder => clicked");
    const modalRef = this.modalService.open(FolderDialogComponent);
    const folder: Document = {
      displayName: "",
      fileName: "",
      isFolder: true,
      level: this.path.length + 1,
      modificationDate: new Date(),
      isSelected: false
    }
    this.translate.get('DOCUMENTS.FOLDERDIALOG.FOLDERNAME').subscribe(translation => {
      modalRef.componentInstance.label = translation;
    });
    this.translate.get('DOCUMENTS.FOLDERDIALOG.CANCELBUTTON').subscribe(translation => {
      modalRef.componentInstance.cancelButtonText = translation;
    });
    this.translate.get('DOCUMENTS.FOLDERDIALOG.OKBUTTON').subscribe(translation => {
      modalRef.componentInstance.okButtonText = translation;
    });
    this.translate.get('DOCUMENTS.FOLDERDIALOG.CREATETITLE').subscribe(translation => {
      modalRef.componentInstance.title = translation;
      folder.displayName = this.translate.instant("DOCUMENTS.FOLDERDIALOG.DEFAULTNAME");
      modalRef.componentInstance.folder = folder;
    });
    //Result handler
    modalRef.result.then(() => {
      this.log("newFolder => open result handler: confirm => closed with yes");
      this.log("newFolder => open result handler: local folder name is " + folder.displayName);
      const isValidName: number = this.documentService.isValidObjectKey(folder.displayName)
      const existingName: Document | undefined = this.isSameNameInDocs(folder.displayName, this.documents)
      if (existingName) {
        const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.FOLDERDIALOG.ERRORNAMEEXIST' };
        this.logError("newFolder => " + errorToastTranslatableText.key);
        this.toastService.showError(errorToastTranslatableText);
      }
      else if (isValidName === 0) {
        this.log("newFolder => open result handler: Create folder");
        let path = this.getPath(this.project!, this.path);
        if (path.length == 0) folder.fileName = folder.displayName;
        else folder.fileName = path + "/" + folder.displayName;
        this.log("newFolder => folder to create: " + JSON.stringify(folder));
        this.documentService.createFolder(this.path.length, this.project!, folder).subscribe(docs => {
          this.initDocsCurrentFilterSort(docs)
        });
      } else {
        this.handleNamingError(isValidName)
      }
    }, () => {
      this.log("newFolder => closed with no");
    });
  }


  confirmDeleteFolder(folderToDelete: Document) {
    this.log("confirmDeleteFolder => called for " + folderToDelete.displayName);
    if (this.project) {
      this.documentService.folderHasContent(this.project, folderToDelete).subscribe(b => {
        if (b) {
          const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.DELETEFOLDERERRORDIALOG.TEXT', params: { foldername: folderToDelete.displayName } };
          this.toastService.showError(errorToastTranslatableText);
        } else {
          this.confirmDelete(folderToDelete);
        }
      });
    }
  }

  confirmDelete(docToDelete: Document) {
    this.log("confirmDelete => called for " + docToDelete.displayName);
    const modalRef = this.modalService.open(ConfirmationDialogComponent);
    const modalComponent = modalRef.componentInstance as ConfirmationDialogComponent
    modalComponent.title = { key: 'DOCUMENTS.CONFIRMATION.TITLE' };
    modalComponent.text = { key: 'DOCUMENTS.CONFIRMATION.QUESTION', params: { filename: docToDelete.displayName } };
    modalComponent.cancelButtonText = { key: 'DOCUMENTS.CONFIRMATION.NO' };
    modalComponent.okButtonText = { key: 'DOCUMENTS.CONFIRMATION.YES' };

    //set result handlers
    modalRef.result.then(() => {
      this.log("confirmDelete => closed with yes");
      if (this.project !== undefined) {
        this.documentService.deleteDocument(this.path.length, this.project, docToDelete).subscribe(s => {
          this.initDocsCurrentFilterSort(s)
          this.unselectAll()
        });
      }
    }, () => { this.log("confirmDelete => closed with no"); }
    );

  }

  downloadSeveral() {
    this.log("downloadSeveral => going to download " + JSON.stringify(this.selectedDocuments))
    forkJoin(
      this.selectedDocuments.map(doc => {
        return this.download(doc)
      })).subscribe(() => {
        this.log("downloadSeveral => finished.");
        this.unselectAll()
      })
  }

  moveSeveral() {
    this.log("moveSeveral => going to move " + JSON.stringify(this.selectedDocuments))
    if (this.selectedDocuments && this.selectedDocuments.length > 0) {
      const modalRef = this.modalService.open(MoveDialogComponent)
      const numOfFiles: number = this.selectedDocuments.length;
      modalRef.componentInstance.documentsToMove = this.selectedDocuments
      modalRef.componentInstance.targetFolder = this.path.at(-1)
      modalRef.componentInstance.project = this.project
      modalRef.componentInstance.path = Array.from(this.path)
      this.translate.get('DOCUMENTS.MOVEDIALOG.NAME').subscribe(translation => {
        modalRef.componentInstance.label = translation
      })
      this.translate.get('DOCUMENTS.MOVEDIALOG.SELECTTARGET').subscribe(translation => {
        modalRef.componentInstance.selecttarget = translation
      })
      this.translate.get('DOCUMENTS.MOVEDIALOG.CANCELBUTTON').subscribe(translation => {
        modalRef.componentInstance.cancelButtonText = translation
      })
      this.translate.get('DOCUMENTS.MOVEDIALOG.OKBUTTON').subscribe(translation => {
        modalRef.componentInstance.okButtonText = translation
      })
      if (numOfFiles > 1) {
        this.translate.get('DOCUMENTS.MOVEDIALOG.SEVERALTITLE', { numOfFiles }).subscribe(translation => {
          modalRef.componentInstance.title = translation
        })
      } else {
        const filename: string = this.selectedDocuments.at(0)!.displayName
        this.translate.get('DOCUMENTS.MOVEDIALOG.TITLE', { documentname: filename }).subscribe(translation => {
          modalRef.componentInstance.title = translation
        })
      }

      //set result handlers
      modalRef.result.then(() => {
        this.log("moveSeveral dialog => closed with yes");
        if (!modalRef.componentInstance.targetFolder) {
          const rootDir: Document = {
            displayName: "",
            fileName: "",
            isFolder: true,
            level: 0,
            modificationDate: new Date(),
            isSelected: false
          }
          modalRef.componentInstance.targetFolder = rootDir
        }
        let currentPath: Document[] = modalRef.componentInstance.path
        this.log("moveSeveral => path : " + JSON.stringify(currentPath))
        this.log("moveSeveral => target folder: " + JSON.stringify(modalRef.componentInstance.targetFolder))
        forkJoin(
          this.selectedDocuments.map(doc => {
            this.log("moveSeveral => doc to move attributes: " + JSON.stringify(doc));
            const docToOverwrite = this.isSameNameInDocs(doc.displayName, modalRef.componentInstance.documents)
            if (docToOverwrite) {
              const errorToastTranslatableText: TranslatableToastInput = { key: 'DOCUMENTS.MOVEDIALOG.ERRORFILEISSAMENOTMOVED', params: { documentname: doc.displayName } };
              const errorToastTranslatableTitle: TranslatableToastInput = { key: 'DOCUMENTS.MOVEDIALOG.ERRORTITLE' };
              this.logError("moveSeveral dialog => " + errorToastTranslatableText.key + ", " + errorToastTranslatableText.params);
              this.toastService.showErrorCloseOnClick(errorToastTranslatableText, errorToastTranslatableTitle);
            }
            else if (!doc.isFolder) {
              const copiedDoc: Document = { ...doc }
              this.moveDocumentHandler(modalRef.componentInstance.targetFolder, copiedDoc, doc);
            } else {
              this.logError("moveSeveral dialog => doc '" + doc.fileName + "' to move is a folder")
            }
            return this.documentService.retrieveDocuments(this.path.length, this.project!,
              this.getPath(this.project!, this.path), false)
          })
        ).subscribe(results => {
          this.log("moveSeveral => finished. Result: " + JSON.stringify(results));
          this.initDocsCurrentFilterSort(results.at(0))
          this.unselectAll()
        })
      }, () => { this.log("moveDialog => closed with no"); }
      );
    } else {
      this.logError("moveSeveral => called without set of document")
    }
  }

  deleteSeveral() {
    this.log("deleteSeveral => going to delete " + JSON.stringify(this.selectedDocuments))
    const numOfFiles: number = this.selectedDocuments.length;
    const filename: string = this.selectedDocuments.at(0)!.displayName
    const modalRef = this.modalService.open(ConfirmationDialogComponent);
    const modalComponent = modalRef.componentInstance as ConfirmationDialogComponent
    modalComponent.title = { key: 'DOCUMENTS.CONFIRMATION.TITLE' };
    if (numOfFiles > 1)
      modalComponent.text = { key: 'DOCUMENTS.CONFIRMATION.QUESTIONSEVERAL', params: { numOfFiles: numOfFiles } };
    else
      modalComponent.text = { key: 'DOCUMENTS.CONFIRMATION.QUESTION', params: { filename: filename } };
    modalComponent.cancelButtonText = { key: 'DOCUMENTS.CONFIRMATION.NO' };
    modalComponent.okButtonText = { key: 'DOCUMENTS.CONFIRMATION.YES' };

    //set result handlers
    modalRef.result.then(() => {
      this.log("deleteSeveral => closed with yes");
      if (this.project !== undefined) {
        forkJoin(
          this.selectedDocuments.map(doc =>
            this.documentService.deleteDocument(this.path.length, this.project!, doc)
          )
        ).subscribe(results => {
          this.log("deleteSeveral => finished. Result: " + JSON.stringify(results));
          this.initDocsCurrentFilterSort(results.at(0))
          this.unselectAll()
        })
      }
    }, () => { this.log("deleteSeveral => closed with no"); }
    );
  }

  download(doc: Document) {
    this.log("download => called for " + doc.displayName);
    if (this.project !== undefined) {
      return this.documentService.downloadDokument(this.project, doc).then(stream => {
        const reader = stream.getReader();
        return new ReadableStream({
          start(controller) {
            // The following function handles each data chunk
            function push() {
              reader.read().then(({ done, value }) => {
                // If there is no more data to read
                if (done) {
                  //console.log('download => push done value: ', done);
                  controller.close();
                  return;
                }
                // Get the data and send it to the browser via the controller
                controller.enqueue(value);
                // Check chunks by logging to the console
                //console.log("download => push chunk: ", value);
                push();
              })
            }
            push();
          }
        });
      }).then(newstream => {
        // Respond with our stream
        return new Response(newstream, { headers: { "Content-Type": "application/octet-stream" } }).blob();
      }).then(result => {
        this.log("download => Response: " + result);
        saveAs(result, doc.displayName);
      });
    }
    return EMPTY;
  }

  protected sortByDefault() {
    let column: DocumentColumn = this.defaultSortColumn;
    let direction: SortDirection = this.defaultSortDirection;
    let event: SortEvent<DocumentColumn> = { column, direction };
    this.log("sortByDefault => calling onSort");
    this.onSort(event);
  }

  private repeatCurrentSort() {
    let column: DocumentColumn = this.defaultSortColumn;
    let direction: SortDirection = this.defaultSortDirection;
    if (this.headers !== undefined) {
      this.headers.forEach(header => {
        if (header.direction !== undefined && header.sortedBy !== undefined) {
          direction = header.direction;
          column = header.sortedBy;
        }
      });
    }
    let event: SortEvent<DocumentColumn> = { column, direction };
    this.onSort(event)
  }



  private listSubDir(document: Document) {
    this.log("listSubDir => for " + document.displayName)
    if (document.isFolder && this.project) {
      if (this.path.length === 0 || this.path[this.path.length - 1].fileName !== document.fileName) {
        this.path.push(document);
        this.documentService.retrieveDocuments(this.path.length, this.project, this.getPath(this.project, this.path), false).subscribe(s => {
          this.log("listSubDir => documents " + JSON.stringify(s))
          this.initDocsDefaultSort(s)
        });
      }
    } else {
      this.logError("listSubDir has to be called with folder document!");
    }
  }

  protected log(message: string) {
    this.loggingService.log(`DocumentComponent: ${message}`);
  }

  protected logError(errorMessage: string) {
    this.loggingService.logError(`DocumentComponent: ${errorMessage}`);
  }

}


