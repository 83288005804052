<div class="text-center mt-5" *ngIf="loading">
  <div class="spinner-border text-secondary">
      <span class="sr-only">{{'APP.LOADING' | translate}}</span>
  </div>
</div>
<div *ngIf="!loading">
  <div *ngIf="project">
    <h2 *ngIf="project.hasFolders">{{'DOCUMENTS.PROJECTFORFOLDER' | translate:{'projectname':project.displayName} }}</h2>
    <h2 *ngIf="!project.hasFolders">{{'DOCUMENTS.PROJECTFORCUSTOMER' | translate:{'projectname':project.displayName,'subproject': project.subProject} }}</h2>
    <div class="input-group input-group-ih me-2">
      <div class="input-group-text input-group-text-ih">{{'DOCUMENTS.UPLOADFILE' | translate}}:&nbsp;</div>
      <input type="button" id="documentsSelectFileButton" [value]="'DOCUMENTS.SELECTFILE' | translate"
        class="btn btn-primary" onclick="document.getElementById('documentsSelectFile').click();" />
      <input type="file" class="file-input" id="documentsSelectFile" name="documentsSelectFile" style="display:none;"
        (change)="onFilesSelected($event)" #fileUpload [ngbTooltip]="'DOCUMENTS.SELECTFILE' | translate" multiple />
      <div id="documentNoFile" *ngIf="fileNames.length === 0" class="input-group-text input-group-text-ih">{{'DOCUMENTS.NOFILE' | translate}}</div>
      <div id="documentFile" *ngIf="fileNames" class="input-group-text input-group-text-ih" [ngbTooltip]="showFilesTooltip()">{{fileNames}}</div>
      <button (click)="upload()" class="btn btn-success btn-icon-ih" [ngbTooltip]="'DOCUMENTS.UPLOAD' | translate"
        id="documentsUploadFile" name="documentsUploadFile">
        <fa-icon [icon]="uploadIcon"></fa-icon>
      </button>
    </div>
    <div class="row" *ngIf="project.hasFolders">
      <div class="col-md-auto">
        <h6 id="documentsPathBreadcrumbs" class="mt-3 breadcrumb-dark-ih">
          <span>{{'DOCUMENTS.PATH' | translate}}:</span>
          <a id="breadcrumbLink-{{project.displayName}}" routerLink="" (click)="onSelectRoot(project)">&nbsp;{{project.displayName}}&nbsp;/</a>
          <a id="breadcrumbLink-{{document.displayName}}" *ngFor="let document of path" routerLink=""
            (click)="onSelectPath(project, document)">
            &nbsp;{{document.displayName}}&nbsp;/</a>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="btn-group">
          <button *ngIf="project.hasFolders && selectCount>0" id="moveSeveralButton" name="moveSeveralButton" (click)="moveSeveral()"
            class="float-start btn-success btn btn-icon-ih" [ngbTooltip]="'DOCUMENTS.MOVESEVERAL' | translate">
            <fa-icon [icon]="moveIcon"></fa-icon>
            {{'DOCUMENTS.MOVE' | translate}}
          </button>
          <button *ngIf="selectCount>0" id="deleteSeveralButton" name="deleteSeveralButton" (click)="deleteSeveral()"
            class="btn btn-success btn-icon-ih" [ngbTooltip]="'DOCUMENTS.DELETESEVERAL' | translate">
            <fa-icon [icon]="deleteIcon"></fa-icon>
            {{'DOCUMENTS.DELETE' | translate}}
          </button>
          <button *ngIf="selectCount>0" id="downloadSeveralButton" name="douwnloadSeveralButton" (click)="downloadSeveral()"
            class="btn btn-success btn-icon-ih" [ngbTooltip]="'DOCUMENTS.DOWNLOADSEVERAL' | translate">
            <fa-icon [icon]="downloadIcon"></fa-icon>
            {{'DOCUMENTS.DOWNLOAD' | translate}}
          </button>
          <div *ngIf="selectCount>0" class="input-group-text input-group-text-ih">
            {{selectCount}} {{'DOCUMENTS.SELECTED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-3" *ngIf="project.hasFolders">
        <button id="newFolderButton" name="newFolderButton" (click)="newFolder()"
          class="float-end btn btn-success btn btn-icon-ih" [ngbTooltip]="'DOCUMENTS.NEWFOLDER' | translate">
          <fa-icon [icon]="folderPlusIcon"></fa-icon>
        </button>
      </div>
    </div>
    <table id="documentTable" class="table table-ih table-striped documents" container="body">
      <thead class=".thead-light">
        <tr>
          <th scope="col" class="align-middle col-1">
            <div class="input-group">
              <input type="checkbox" class="checkbox-ih checkbox-filter-ih" data-cy="filter-selected"
                [ngbTooltip]="'FILTER.SELECTALLFILTERHINT' | translate" [(ngModel)]="selectedFilter"
                (ngModelChange)="onFilter()" />
            </div>
          </th>
          <th scope="col" class="th-filter-ih col-5">
            <div class="input-group">
              <input type="text" class="form-control input-filter-ih" data-cy="filter-name"
                placeholder="{{'DOCUMENTS.FILENAME'|translate}}" [(ngModel)]="nameFilter"
                (ngModelChange)="onFilter()">
              <button *ngIf="nameFilter != ''" (click)="clearNameFilter()"
                class="btn btn-sm filter-clear-btn" [ngbTooltip]="'FILTER.CLEAR' | translate" container="body"
                data-cy="filter-name-clear">
                <fa-icon [icon]="clearIcon"></fa-icon>
              </button>
            </div>
          </th>
          <th scope="col" class="th-filter-ih col-5">
            <div class="input-group">
              <input type="text" class="form-control input-filter-ih" data-cy="filter-date"
                placeholder="{{'DOCUMENTS.MODIFICATIONDATE'|translate}}" [(ngModel)]="dateFilter"
                (ngModelChange)="onFilter()">
              <button *ngIf="dateFilter != ''" (click)="clearDateFilter()" class="btn btn-sm filter-clear-btn"
                [ngbTooltip]="'FILTER.CLEAR' | translate" container="body" data-cy="filter-date-clear">
                <fa-icon [icon]="clearIcon"></fa-icon>
              </button>
            </div>
          </th>
          <th scope="col" class="col-1"></th>
        </tr>
        <tr [ngbTooltip]="'SORTHINT' | translate">
          <th style="width: 10em;" scope="col">
            <input *ngIf="isDocInDocs()" id="selectAllCheckbox" type="checkbox" class="checkbox-ih" [ngbTooltip]="'DOCUMENTS.SELECTALLHINT' | translate"
              [(ngModel)]="selectAll" (ngModelChange)="toggleSelectAll()" />
          </th>
          <th style="max-width: 20em;" scope="col" sortable="displayName" (sort)="onSort($event)" >{{'DOCUMENTS.FILENAME'
            |translate}}</th>
          <th scope="col" sortable="modificationDate" [direction]="defaultSortDirection" (sort)="onSort($event)" >
            {{'DOCUMENTS.MODIFICATIONDATE' |translate}}</th>
          <th scope="col">{{'DOCUMENTS.ACTION' |translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr id="document-row-{{document.displayName}}" *ngFor="let document of documents">
          <td *ngIf="!document.isFolder">
            <input id="document-checkbox-{{document.displayName}}" type="checkbox" class="checkbox-ih" [(ngModel)]="document.isSelected"
            (ngModelChange)="toggleSelect(document)"/>
          </td>
          <td *ngIf="document.isFolder">
          </td>
          <td style="max-width: 20em;" *ngIf="document.isFolder" id="document-cell-filename-{{document.displayName}}">
            <fa-icon [icon]="folderIcon"></fa-icon>
            <a id="document-link-{{document.displayName}}" routerLink=""
              (click)="onClick(document)">&nbsp;{{document.displayName}}</a>
          </td>
          <td style="max-width: 20em;" *ngIf="isPdf(document)" id="document-cell-filename-{{document.displayName}}">
            <fa-icon [icon]="pdfFileIcon"></fa-icon><a routerLink=""
              (click)="onClick(document)">&nbsp;{{document.displayName}}</a>
          </td>
          <td style="max-width: 20em;" *ngIf="isImage(document)" id="document-cell-filename-{{document.displayName}}">
            <fa-icon [icon]="imageFileIcon"></fa-icon><a routerLink=""
              (click)="onClick(document)">&nbsp;{{document.displayName}}</a>
          </td>
          <td style="max-width: 20em;" *ngIf="isAny(document)" id="document-cell-filename-{{document.displayName}}">
            <fa-icon [icon]="fileIcon"></fa-icon><a routerLink=""
              (click)="onClick(document)">&nbsp;{{document.displayName}}</a>
          </td>
          <td id="document-cell-modificationdate-{{document.displayName}}-{{document.modificationDate}}">
            {{document.modificationDate | localizedDate}}
          </td>
          <td>
            <div *ngIf="document.isFolder">
              <div class="btn-group">
                <button id="document-rename-btn-{{document.displayName}}" (click)="rename(document)"
                  class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.RENAME' | translate">
                  <fa-icon [icon]="renameIcon"></fa-icon>
                </button>
                <button id="document-move-btn-{{document.displayName}}" (click)="move(document)"
                  class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.MOVE' | translate">
                  <fa-icon [icon]="moveIcon"></fa-icon>
                </button>
                <button id="document-delete-btn-{{document.displayName}}" (click)="confirmDeleteFolder(document)"
                  class="btn btn-danger btn-sm" [ngbTooltip]="'DOCUMENTS.DELETE' | translate">
                  <fa-icon [icon]="deleteIcon"></fa-icon>
                </button>

              </div>
            </div>
            <div *ngIf="project.hasFolders">
              <div *ngIf="!document.isFolder">
                <div class="btn-group">
                  <button id="document-rename-btn-{{document.displayName}}" (click)="rename(document)"
                    class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.RENAME' | translate">
                    <fa-icon [icon]="renameIcon"></fa-icon>
                  </button>
                  <button id="document-move-btn-{{document.displayName}}" (click)="move(document)"
                    class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.MOVE' | translate">
                    <fa-icon [icon]="moveIcon"></fa-icon>
                  </button>
                  <button id="document-delete-btn-{{document.displayName}}" (click)="confirmDelete(document)"
                    class="btn btn-danger btn-sm" [ngbTooltip]="'DOCUMENTS.DELETE' | translate">
                    <fa-icon [icon]="deleteIcon"></fa-icon>
                  </button>
                  <button id="document-download-btn-{{document.displayName}}" (click)="download(document)"
                    class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.DOWNLOAD' | translate">
                    <fa-icon [icon]="downloadIcon"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="!project.hasFolders">
              <div class="btn-group">
                <button id="document-delete-btn-{{document.displayName}}" (click)="confirmDelete(document)"
                  class="btn btn-danger btn-sm" [ngbTooltip]="'DOCUMENTS.DELETE' | translate">
                  <fa-icon [icon]="deleteIcon"></fa-icon>
                </button>
                <button id="document-download-btn-{{document.displayName}}" (click)="download(document)"
                  class="btn btn-success btn-sm" [ngbTooltip]="'DOCUMENTS.DOWNLOAD' | translate">
                  <fa-icon [icon]="downloadIcon"></fa-icon>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
</div>
</div>