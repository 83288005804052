<div class="modal-header modal-header-ih">
    <h4 class="modal-title modal-title-ih" data-cy="document-rename-dialog-title">{{title}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('CROSS_CLICK')"></button>
  </div>
  <div class="modal-body modal-body-ih">
    <div *ngIf="document">
      <div class="formwidget-ih">
        <label id="label-input-document-name" for="documentname">{{label}}</label>
        <input id="documentname" class="form-control" required #name="ngModel" type="text" [(ngModel)]="document.displayName"
        ngbAutoFocus #docNameInput (focus)="docNameInput.select()">
      </div>
    </div>
    <div class="modal-footer modal-footer-ih">
      <button type="button" class="btn btn-outline-dark submit-ih"
        (click)="activeModal.dismiss('CANCEL_CLICK')" data-cy="cancel-document-rename-button">{{cancelButtonText}}</button>
      <button type="button" class="btn btn-outline-dark submit-ih"
        (click)="activeModal.close('OK_CLICK')" data-cy="ok-document-rename-button">{{okButtonText}}</button>
    </div>
  </div>
