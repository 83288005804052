import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-overwrite-dialog',
  standalone: true,
  imports: [NgbTooltipModule, TranslateModule],
  templateUrl: './overwrite-dialog.component.html',
  styleUrl: './overwrite-dialog.component.css'
})
export class OverwriteDialogComponent {
  @Input() title: any;
  @Input() label: any;
  @Input() okButtonText: any;
  @Input() noButtonText: any;
  @Input() cancelButtonText: any;

  @Input({required: true}) toOverwrite!: Document;
  @Input({required: true}) overwriteWith!: Document;
  @Input({required: true}) currentPath!: Document[];

  constructor(public activeModal: NgbActiveModal, public translate: TranslateService) { }
}
