import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { ProjectsComponent } from './components/projects/projects.component';

import { registerLocaleData } from '@angular/common';
import { LocalizedDatePipe, MeaCommonsModule } from '@inspiring-health/mea-commons';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { FolderDialogComponent } from './components/folder-dialog/folder-dialog.component';
import { MoveDialogComponent } from './components/move-dialog/move-dialog.component';
import { RenameDialogComponent } from './components/rename-dialog/rename-dialog.component';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

export function httpTranslateLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, [
    // if we choose this order, we can overwrite keys of the lib ;-)
     { prefix: './assets/mea-commons/i18n/', suffix: '.json' }, //your lib assets
     { prefix: './assets/i18n/', suffix: '.json' },//host app i18n assets
   ])
 }

@NgModule({
  declarations: [
    AppComponent,
    DocumentsComponent,
    ProjectsComponent,
    FolderDialogComponent,
    RenameDialogComponent,
    MoveDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    FontAwesomeModule,
    FormsModule,
    NgbModule,
    AppRoutingModule,
    MeaCommonsModule.forRoot({ 
        production: environment.production 
      },
      {
        accountId: environment.accountid,
        env: environment.env,
        identityPoolId: environment.identitypoolid,
        region: environment.region,
        userpoolId: environment.userpoolid
      }
    )
  ],
  providers: [ LocalizedDatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
