// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  region: "eu-central-1",
  accountid: "775929965301",
  userpoolid: "eu-central-1_mxg4VTTYb",
  identitypoolid: "eu-central-1:a2ff182e-53ae-4397-a155-768472ec68ca",
  clientid: "45vin5n0f65qpljb34k9teb3uc",
  projectNamePrefix: "mea-data-exchange-dev-",
  customRoleArnPrefix: "arn:aws:iam::775929965301:role/",
  env: "dev"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
