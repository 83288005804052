import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Document } from '../../models/document.model';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.css']
})
export class RenameDialogComponent {

  @Input() title: any;
  @Input() label: any;
  @Input() okButtonText: any;
  @Input() cancelButtonText: any;

  @Input() document?: Document;

  constructor(public activeModal: NgbActiveModal) { }


}

