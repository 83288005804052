import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from 'src/app/models/project.model';
import { DocumentsBaseComponent } from 'src/app/shared/documents-base.component';
import { Document } from '../../models/document.model';
import { LoggingService } from '@inspiring-health/mea-commons';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-move-dialog',
  templateUrl: './move-dialog.component.html',
  styleUrl: './move-dialog.component.css'
})
export class MoveDialogComponent extends DocumentsBaseComponent {

  @Input() title: any;
  @Input() selecttarget:any;
  @Input() label: any;
  @Input() okButtonText: any;
  @Input() cancelButtonText: any;

  @Input({required: true}) documentToMove!: Document;
  @Input() documentsToMove!: Document[];
  @Input() targetFolder!: Document;
  @Input({required: true}) project!: Project;

  constructor(loggingService: LoggingService, documentService: DocumentService, public activeModal: NgbActiveModal) { 
    super(loggingService, documentService)
  }

  toMoveIsSame(doc: Document): boolean {
    if (this.documentToMove && this.documentToMove.fileName === doc.fileName) {
      return true
    } else {
      return false
    }
  }

  ngOnInit(): void {
    this.log("ngOnInit selected project: " + this.project?.name);
    if (this.project) {
      this.documentService.retrieveDocuments(this.path.length, this.project, this.getPath(this.project, this.path), false).subscribe(s => {
        this.documents = s;
        this.sortByDefault();
      });
    }
  }

  protected sortByDefault() {
    this.documents?.sort((d1, d2) => 
      d1.displayName.localeCompare(d2.displayName)
    )
  }

  onClick(folder: Document | undefined) {
    if (folder) {
      this.log("onClick => for " + folder.displayName)
      if (folder.isFolder && this.project) {
        if (this.path.length === 0 || this.path[this.path.length - 1].fileName !== folder.fileName) {
          this.targetFolder = folder
          this.path?.push(folder);
          this.documentService.retrieveDocuments(this.path.length, this.project, this.getPath(this.project, this.path), false).subscribe(s => {
            this.documents = s;
            this.sortByDefault();
          });
        }
      } else {
        this.logError("onClick => has to be called with folder document!");
      }
    } else {
      this.log("onClick => for ..")
      this.log("onClick => current target folder is " + JSON.stringify(this.targetFolder))
      if (this.path.length > 0) {
        this.log("onClick => path " + JSON.stringify(this.path))
        this.path.pop()
        this.targetFolder = this.path[this.path.length - 1]
        this.log("onClick => newTargetFolder " + JSON.stringify(this.targetFolder))
        this.documentService.retrieveDocuments(this.path.length, this.project, this.getPath(this.project, this.path), false).subscribe(s => {
          this.documents = s;
          this.sortByDefault();
        });
      }
    }
  }

  protected logError(errorMessage: string) {
    this.loggingService.logError(`MoveDialogComponent: ${errorMessage}`);
  }

  protected log(message: string) {
    this.loggingService.log(`MoveDialogComponent: ${message}`);
  }

}
